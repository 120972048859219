import React from 'react';
import { motion } from "framer-motion";

const GameWinModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay" onClick={onClose}  >
      <motion.div 
        className="game-win-modal-content" 
        initial={{ opacity:0, rotate:0 }} 
        animate={{ opacity:1, rotate:725}}
        transition={{ duration: 0.75, delay: 0.1, type: "intertia", velocity:50 }}
      >
        <h2 className='modal-heading2'>YOU'VE WON!!!</h2>
        <div className='modal-text'>
          <div className='modal-heading3'>(and so has the country!!!)</div>
          <div className='modal-heading4'>Now take a shot, enjoy the Schadenfreude, and prepare for the invitable disappointment of an incoming Labour government...</div>
          <div className="modal-instructions-block"></div>
        </div>
      </motion.div>
    </div>
  );
};

export default GameWinModal;