import React from 'react';
import { motion } from "framer-motion";

const AboutModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <motion.div
        className="modal-content"
        initial={{ opacity:0 }} 
        animate={{ opacity:1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className='modal-heading2'>were you up for portillo?</h2>
        <div className='modal-text'>
          <div className='modal-heading3'>How to play</div>
          <div className='modal-heading4'>
            Honestly - it's bingo - how hard can it be?<br/>
          </div>
            <div className="modal-instructions-block">
              <ol>
                <li>Click on the losers</li>
                <li>Enjoy the Schadenfreude</li>
                <li>Prepare for the invitable disappointment of an incoming Labour government</li>
                <li>Err...</li>
                <li>That's it.</li>
              </ol>
                
            </div>
        </div>
        <div className='modal-text-credits'>
          <p>Built by <a href="https://trashpanda.cc" target="_blank">Tim</a></p>

          <div className='lucy-credits'>
            <div className="smallish">with a lot of help from <a href="https://www.linkedin.com/in/lucytoman/" target="_blank">Lucy</a></div>
            <span className="smaller">&nbsp;who would like to point out that she isn't responsible for the messy CSS<br/></span>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AboutModal;