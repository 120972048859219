import { loadFromLocalStorage } from "./GameLib";

const setupGameStates = async (reload = false) => {

  console.log("setup games")

  /*
    [
      {
        gameName: string
        mps: [
          id: integer
          firstName: string
          lastName: string
          party: string
          constituency: string
          url: string
          image: string
          lost: boolean
          visible: boolean
        ]
      }
    ]
  */

  if (reload == false) {
    // Test if there's already local data, and don't reload if there is
    const result = loadFromLocalStorage('games')
    if (result) { return }
  }
  
  const response = await fetch('/data/allMps.json');
  const mps = await response.json()
  
  // TORIES
  var tories = mps.filter((mp) => mp.party === 'Conservative')
  var shuffled = shuffleAndDeal(tories)
  const conservativeGame = {
    gameName: 'conservatives',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // LABOUR
  var labour = mps.filter((mp) => mp.party === 'Labour')
  var shuffled = shuffleAndDeal(labour)
  const labourGame = {
    gameName: 'labour',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // GOBSHITES
  var gobshites = mps.filter((mp) => mp.gobshite === true)
  var shuffled = shuffleAndDeal(gobshites)
  const gobshitesGame = {
    gameName: 'gobshites',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // BOX OF FROGS
  var fruitcakes = mps.filter((mp) => mp.fruitcakes === true)
  var shuffled = shuffleAndDeal(fruitcakes)
  const fruitcakesGame = {
    gameName: 'fruitcakes',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // HEADBANGERS
  var headbangers = mps.filter((mp) => mp.headbanger === true)
  var shuffled = shuffleAndDeal(headbangers)
  const headbangerGame = {
    gameName: 'headbangers',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // BREXITEERS
  var brexiteers = mps.filter((mp) => mp.brexiteer === true)
  var shuffled = shuffleAndDeal(brexiteers)
  const brexiteersGame = {
    gameName: 'brexiteers',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // LETTUCES
  var lettuces = mps.filter((mp) => mp.lettuce === true)
  var shuffled = shuffleAndDeal(lettuces)
  const lettucesGame = {
    gameName: 'lettuces',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // CABINET
  var cabinet = mps.filter((mp) => mp.cabinet === true)
  var shuffled = shuffleAndDeal(cabinet)
  const cabinetGame = {
    gameName: 'cabinet',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // REDWALLERS
  var redwall = mps.filter((mp) => mp.redwall === true)
  var shuffled = shuffleAndDeal(redwall)
  const redwallGame = {
    gameName: 'redwall',
    mps: shuffled,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // GOBSHITES
  var vulnerables = mps.filter((mp) => mp.vulnerable === true)
  var ordered = vulnerables.sort((a, b) => a['rank'] - b['rank']);
  const vulnerableGame = {
    gameName: 'vulnerables',
    mps: ordered,
    boardState: Array.from({ length: 4 }, () => Array(5).fill(false))
  }

  // Create Games:
  const games = [ conservativeGame, labourGame, gobshitesGame, fruitcakesGame, headbangerGame, brexiteersGame, lettucesGame, redwallGame, cabinetGame, vulnerableGame ]

  localStorage.setItem('games', JSON.stringify(games));
  
}

const shuffleAndDeal = ((array) => {
  const shuffledArray = array.map(item => ({ ...item })); // Create a shallow copy of each object
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
  }
  return shuffledArray
  //return shuffledArray.slice(0, 20)
})

export { setupGameStates }