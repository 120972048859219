


export const nukeLocalStorage = () => {
  console.log('nuking')
  localStorage.removeItem('games');
}

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const loadFromLocalStorage = (key) => {
  const storedValue = localStorage.getItem(key);
  if (storedValue) {
    return JSON.parse(storedValue);
  }
  return null; // or you can return an empty array [] if you prefer
};

export const testForFullHouse = ((grid) => {
  const items = grid.filter(item => item === true);
  return items.length === 20 ? true : false
})

export const testForRow = (grid) => {
  return false;
}

export const testForColumn = (grid) => {
  return false;
}