import { transpose } from 'mathjs';
import Buttons from './components/Buttons';
import AboutModal from './components/AboutModal';
import React, { useState, useEffect } from 'react';
import GameWinModal from './components/GameWinModal';
import { setupGameStates } from './lib/setupGameStates';
//import InterimWinModal from './components/InterimWinModal';

const Bingo = () => {

  const [games, setGames] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [liveGame, setLiveGame] = useState(null);
  const [currentGame, setCurrentGame] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedCards, setSelectedCards] = useState(0);
  const [isGameWinOpen, setIsGameWinOpen] = useState(false);
  // const [interimWinType, setInterimWinType] = useState("");
  // const [rowAlreadyWon, setRowAlreadyWon] = useState(false);
  // const [colAlreadyWon, setColAlreadyWon] = useState(false);
  // const [isInterimWinOpen, setisInterimWinOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulating asynchronous setupGameStates function
        await setupGameStates(true); 
        const gamesJson = localStorage.getItem('games');
        const gamesArray = JSON.parse(gamesJson);
        setGames(gamesArray); 
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, []); 

  useEffect(() => {
  }, [games]);

  useEffect( () => {
  }, [selectedCards])

  const handleLoadingClick = (gameName) => {
    setLiveGame(gameName);
    const gameNumber = findGameByName(gameName)
    setCurrentGame(gameNumber); 
  }

  const toggleSquare = (clickedMp, row, col) => {

    var newLostValue = !clickedMp.lost
    var score = selectedCards

    if (newLostValue) {
      setSelectedCards(score += 1)
    } else {
      setSelectedCards(score -= 1)
    }
    
    updateMpLostStatus(currentGame, clickedMp.id, newLostValue)
    updateBoardState(row, col)

    checkForBoardWin();
    // checkColWin();
    // checkRowWin();
  }

  const findGameByName = ((gameName) => {
    const gameIndex = games.findIndex(game => game.gameName === gameName);
    return gameIndex
  });

  const updateMpLostStatus = (gameIndex, mpId, newLostValue) => {
    let updatedGames = [...games];
    let game = updatedGames[gameIndex];
    let mp = game.mps.find(mp => mp.id === mpId);
    if (mp) {
      mp.lost = newLostValue;
    }
  }

  const updateBoardState= (row, col) => {
    let updatedGames = [...games];
    let game = updatedGames[currentGame];
    let updatedBoardState = [...game.boardState];
    updatedBoardState[row][col] = !updatedBoardState[row][col];

    game = {
      ...game,
      boardState: updatedBoardState
    };

    updatedGames[currentGame] = game;
    setGames(updatedGames);
  }

  // const checkForRowWin = () => {
  //   const game = games[currentGame]
  //   const boardState = game.boardState
  //   let result = boardState.some(innerArray => innerArray.every(value => value === true));
  //   return result;
  // }

  // const checkForColWin = () => {
  //   const game = games[currentGame]
  //   const boardState = game.boardState
  //   const transposed = transpose(boardState)
  //   let result = transposed.some(innerArray => innerArray.every(value => value === true));
  //   return result
  // }

  // const checkColWin = () => {
  //   if (checkForFullBoardWin() === true) { return };
  //   if (colAlreadyWon === true) { return };
  //   const game = games[currentGame]
  //   const boardState = game.boardState
  //   const transposed = transpose(boardState)
  //   let result = transposed.some(innerArray => innerArray.every(value => value === true));
  //   if (result) {
  //     setInterimWinType("COLUMN");
  //     setColAlreadyWon(true);
  //     toggleInterimWinModal();
  //   }
  //   return result
  // }

  // const checkRowWin = () => {
  //   if (checkForFullBoardWin() === true) { return };
  //   const game = games[currentGame]
  //   const boardState = game.boardState
  //   let result = boardState.some(innerArray => innerArray.every(value => value === true));
  //   if (result) {
  //     setInterimWinType("ROW")
  //     toggleInterimWinModal();
  //   }
  //   return result
  // }

  const checkForFullBoardWin = () => {
    const game = games[currentGame]
    const boardState = game.boardState
    const result = boardState.flat().every(value => value === true);
    return result  
  }

  const checkForBoardWin = () => {
    if (checkForFullBoardWin() === true) { toggleGameWinModal() }
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleGameWinModal = () => {
    setIsGameWinOpen(!isGameWinOpen);
  };

  // const toggleInterimWinModal = () => {
  //   setisInterimWinOpen(!isInterimWinOpen);
  // }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <div className={`container pyro`}>
      <div className="titleBar bevan-regular">were you up for portillo?</div>

      {/* <div className={`${checkForRowWin() ? 'before' : ''} ${checkForRowWin() ? 'before' : ''}`}></div>
      <div className={`${checkForColWin() ? 'after' : ''} ${checkForColWin() ? 'before' : ''}`}></div> */}
      
      <div className="grid-container">

      <AboutModal isOpen={isModalOpen} onClose={toggleModal} />

      <GameWinModal isOpen={isGameWinOpen} onClose={toggleGameWinModal}  />

      {/* <InterimWinModal isOpen={isInterimWinOpen} onClose={toggleInterimWinModal} winType={interimWinType} /> */}
      
      <Buttons
        handleLoadingClick={handleLoadingClick}
        toggleModal={toggleModal}
        toggleGameWinModal={toggleGameWinModal}
        liveGame={liveGame}
      />
      <div className="grid">
          {games[currentGame].mps.slice(0, 20).map((mp, index) => {
            const row = Math.floor(index / 5); // Assuming 5 columns
            const column = index % 5;
            return (
              <div
                key={mp.id}
                className='square'
                data-row={row}
                data-column={column}
                onClick={(event) => {
                  const row = event.currentTarget.dataset.row;
                  const col = event.currentTarget.dataset.column;
                  toggleSquare(mp, row, col);
                }}
              >
                <div className={`stamp-container ${mp.lost ? '' : 'stamp-container-hidden'}`}></div>
                <div className='mp-image-container'>
                  <img src={`${process.env.PUBLIC_URL}/img/mps/${mp.id}.jpg`} className='mp-image' />
                  <div className={`name-banner name-banner-${mp.party}`}>
                    <div className={`mp-name name-banner-${mp.party}`}>
                      {mp.firstName} {mp.lastName}
                    </div>
                    <div className={`mp-constituency name-banner-${mp.party}`}>
                      {mp.constituency}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Bingo;
