import React from 'react';

const Buttons = ({ handleLoadingClick, toggleModal, toggleGameWinModal, liveGame }) => {

  const buttons = [
    { name: 'conservatives', label: 'Tories' },
    { name: 'labour', label: 'Labour' },
    { name: 'cabinet', label: 'Cabinet of C*nts' },
    { name: 'redwall', label: 'Red Wallers' },
    { name: 'vulnerables', label: 'Squeaky Bums' },
    { name: 'gobshites', label: 'Gobshites' },
    { name: 'fruitcakes', label: 'Fruitcakes' },
    { name: 'headbangers', label: 'Headbangers' },
    // { name: 'brexiteers', label: 'Brexiteers' },
    { name: 'lettuces', label: 'Lettuces' },
  ];

  const highlightStyle = (name) => ({
    color: liveGame === name ? 'yellow' : 'white',
  });

  return (
    <div className='buttons-section'>
      <div>
        {buttons.map((button) => (
          <>
            <div 
              className='button-53'
              onClick={() => handleLoadingClick(button.name)}
              key={button.name}
              style={highlightStyle(button.name)}
            >
              {button.label}
            </div>
            <div className='button-spacer'></div>
          </>
        ))}
      </div>
      <div className='button-53' onClick={() => toggleModal()}>
        About
      </div>
    </div>
  );
};

export default Buttons;